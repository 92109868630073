import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export interface VideoArticleExitMarketingTextProps extends ComponentProps<'span'> {}

export const VideoArticleExitMarketingTextComponent: Component<VideoArticleExitMarketingTextProps> = ({
  children,
  ...props
}) => {
  const className = useClassName('exit_label', props);

  return <span {...mergeProps({ className }, props)}>{children}</span>;
};
