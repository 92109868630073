import { VideoB2BCampaign } from '../types/video';
import { UserInformation } from './types';

export const findB2BMediaCandidate = (
  videoB2BCampaigns: VideoB2BCampaign[],
  userInformation: UserInformation
): VideoB2BCampaign | undefined => {
  const campaignsAvailableForUser = videoB2BCampaigns.filter((campaign) => {
    const campaignViewsCount = userInformation.seenCampaigns[campaign.campaignId] ?? 0;
    return campaignViewsCount < parseInt(campaign.userFrequency);
  });

  return campaignsAvailableForUser[0];
};
