import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import { stripHtml } from '@/utils/stripHtml';
import { useJWPlayer } from './JWPlayerContext';

export interface VideoArticleVideoContainerNextVideoTitleProps extends ComponentProps<'p'> {
  mediaId?: string;
}

export const VideoArticleVideoContainerNextVideoTitleComponent: Component<
  VideoArticleVideoContainerNextVideoTitleProps
> = ({ mediaId, children, ...props }) => {
  const { currentMedia } = useJWPlayer();
  const className = useClassName('videoContainer_nextVideo_title', props);

  const videoHasChanged = currentMedia?.mediaid && currentMedia?.mediaid !== mediaId;
  const videoTitle = stripHtml(currentMedia?.title);
  return (
    <p {...mergeProps({ className }, props)}>
      Efter reklamen: <span>{videoHasChanged ? videoTitle : children}</span>
    </p>
  );
};
