import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import { useCallback, useEffect, useState } from 'react';
import { useJWPlayer } from './JWPlayerContext';
import { Play } from './constants/playEvents';
import { usePlayerEvent } from './hooks/usePlayerEvent';

export interface VideoArticleUnmuteProps extends ComponentProps<'button'> {}

type UnmuteButtonState = 'off' | 'on' | 'show';

export const VideoArticleUnmuteComponent: Component<VideoArticleUnmuteProps> = ({ children, ...props }) => {
  const [showUnmute, setShowUnmute] = useState<UnmuteButtonState>('off');
  const [animationClasses, setAnimationClasses] = useState('show');
  const { player, isMute, currentMedia } = useJWPlayer();

  const className = useClassName('unmute', props, {
    className: [animationClasses, !isMute && 'has-volume', 'jwplayer__unmute'],
  });

  const mediaId = currentMedia ? currentMedia.mediaid : '';

  if (isMute && showUnmute === 'on') {
    setShowUnmute('show');
  }

  const onUnmuteClick = () => {
    player.setMute(false);
  };

  const onPlay = useCallback(() => {
    let unmute: UnmuteButtonState = 'on';
    if (player.getMute()) {
      unmute = 'show';
    }
    setShowUnmute(unmute);
  }, [player]);

  usePlayerEvent(player, Play, onPlay);
  useEffect(() => {
    if (showUnmute !== 'show') {
      return () => {};
    }
    const timeouts: Array<NodeJS.Timeout> = [];

    timeouts.push(setTimeout(() => setAnimationClasses('show expanded'), 3000));
    timeouts.push(setTimeout(() => setAnimationClasses('show'), 7000));
    timeouts.push(setTimeout(() => setAnimationClasses(''), 17000));

    return () => {
      timeouts.map((t) => clearTimeout(t));
    };
  }, [showUnmute]);

  return (
    <>
      {showUnmute === 'show' && (
        <button onClick={onUnmuteClick} {...mergeProps({ className }, props)}>
          {children}
        </button>
      )}
    </>
  );
};
