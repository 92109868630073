import { useClassName } from '@/styles/JwVideoArticle';

import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export interface VideoArticleExitLinkProps extends ComponentProps<'a'> {
  commercialLink: string;
}

export const VideoArticleExitLinkComponent: Component<VideoArticleExitLinkProps> = ({
  commercialLink,
  children,
  ...props
}) => {
  const className = useClassName('exit_link', props);
  return (
    <a target="_blank" rel="noopener nofollow" href={commercialLink} {...mergeProps({ className }, props)}>
      {children}
    </a>
  );
};
