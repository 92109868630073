import { useEffect } from 'react';
import { JwPlayerEventTypes } from '../constants/playEvents';

export function usePlayerEvent(player: any, event: JwPlayerEventTypes, callback: Function | undefined) {
  useEffect(() => {
    if (!player || !callback) {
      return () => {};
    }

    player.on(event, callback);

    return () => {
      player.off(event, callback);
    };
  }, [player, event, callback]);
}
