const Play = 'play';
const Pause = 'pause';
const Mute = 'mute';
const Unmute = 'unmute';
const FirstFrame = 'firstFrame';
const Time = 'time';
const PlaylistItem = 'playlistItem';
const Stop = 'stop';
const Complete = 'complete';

const RelatedPluginReady = 'relatedReady';
const AdStart = 'adBreakStart';
const AdEnd = 'adBreakEnd';
const Float = 'float';

type Play = typeof Play;
type Float = typeof Float;
type Pause = typeof Pause;
type Mute = typeof Mute;
type Unmute = typeof Unmute;
type Time = typeof Time;
type FirstFrame = typeof FirstFrame;
type RelatedPluginReady = typeof RelatedPluginReady;
type PlaylistItem = typeof PlaylistItem;
type Stop = typeof Stop;
type Complete = typeof Complete;
type AdStart = typeof AdStart;
type AdEnd = typeof AdEnd;

type JwPlayerEventTypes =
  | Play
  | Pause
  | Mute
  | Unmute
  | FirstFrame
  | Time
  | RelatedPluginReady
  | PlaylistItem
  | Stop
  | Complete
  | AdStart
  | AdEnd
  | Float;

export {
  AdEnd,
  AdStart,
  Complete,
  FirstFrame,
  Float,
  Mute,
  Pause,
  Play,
  PlaylistItem,
  RelatedPluginReady,
  Stop,
  Time,
  Unmute,
};

export type { JwPlayerEventTypes };
