import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { VideoB2BCampaign } from '../types/video';

export const getVideoCampaigns = async (videoVertical: string, videoBrand: string) => {
  const url = getUrl('/api/native-video-campaigns');
  if (!url) {
    return [];
  }

  const response = await http.get<VideoB2BCampaign[]>(url.href);

  const data = (response?.data ?? []).filter(
    (item) => (item.brand.includes(videoBrand) || videoBrand === 'all') && item.verticals.includes(videoVertical),
  );
  return data || [];
};
