import { getUrl } from '@/utils/getUrl';
import { isClient } from '@/utils/isClient';
import { isDev } from '@/utils/isDev';
import { logger } from '@/utils/logger';
import { tryGetWindowProp } from '@/utils/tryGetWindowProp';
import getConfig from 'modules/config';
import qs from 'query-string';
import { JW_CDN_URL } from '../constants';
import { ArticleInfo } from '../types/articleInfo';
import { replaceTag } from '../utils/replaceTag';

const addArticleDataToAdTag = ({
  tag,
  articleInfo,
  videoData,
  experimentGroup,
  adsPpId,
  isVerticalVideo,
  isSecondNativeArticleLoaded,
}: {
  tag: string;
  articleInfo?: ArticleInfo;
  videoData: any;
  experimentGroup: any;
  adsPpId: any;
  isVerticalVideo?: boolean;
  isSecondNativeArticleLoaded?: boolean;
}) => {
  const { articleId = '', articleVerticals = [] } = articleInfo ?? {};
  const { wasYouplay } = videoData;
  const { url, query: tagQuery } = qs.parseUrl(tag);

  const custParams = tagQuery.cust_params || '';
  const [params] = Array.isArray(custParams) ? custParams : [custParams];

  const custParamsObj = qs.parse(params as string);
  // Add 'verticals'
  if (articleVerticals?.length) {
    // Put verticals in a set to create a list with unique values
    const verticalsSet = new Set([articleVerticals]);
    const verticals = Array.from(verticalsSet);
    // eslint-disable-next-line
    custParamsObj['Vertikal'] = verticals.join(',');
  }

  if (experimentGroup) {
    // eslint-disable-next-line
    custParamsObj['abgroup'] = experimentGroup;
  }
  // Add converted to youplay measurement
  if (wasYouplay) {
    // maybe have youplay ID here?
    // eslint-disable-next-line
    custParamsObj['videoplayer'] = 'youplay';
  }

  custParamsObj['demandmanager'] = '1';
  custParamsObj['pos'] = isVerticalVideo ? 'verticalvideo' : 'auto';
  if (isVerticalVideo) {
    custParamsObj['page'] = 'floatingvideo';
  }
  if (isSecondNativeArticleLoaded) {
    custParamsObj['secondarticle'] = 'true';
  }

  return adsPpId
    ? `${url}?${qs.stringify({
        ...tagQuery,
        cust_params: qs.stringify(custParamsObj),
        ppid: adsPpId,
      })}`
    : `${url}?${qs.stringify({
        ...tagQuery,
        cust_params: qs.stringify(custParamsObj),
      })}`;
};

type WindowAds = {
  getAdScheduleId: () => any;
  getVideoExperimentGroup: () => any;
  getVideoPpId: () => string;
};

export const adsSetup = async ({
  articleInfo,
  isVerticalVideo,
  autoStart,
  wasYouplay,
  isSecondNativeArticleLoaded,
}: {
  articleInfo?: ArticleInfo;
  isVerticalVideo?: boolean;
  autoStart: string | boolean;
  wasYouplay: boolean;
  isSecondNativeArticleLoaded?: boolean;
}) => {
  const ads = await tryGetWindowProp<WindowAds, undefined>('ads', { fallbackValue: undefined });

  if (!ads) {
    logger.warn(`adsSetup: window.ads not initialized`);
  }

  let adsScheduleId: any;

  if (isDev) {
    adsScheduleId = process.env.OCELOT_ADS_SCHEDULE_ID;
  } else {
    const { autoPlay, clickToPlay } = getConfig('ads.video');

    if (autoPlay && clickToPlay) {
      // Fetch from Ads Microservice, brand specific adscheduleid based on if a video is autoplay or click to play
      adsScheduleId = autoStart === 'viewable' ? autoPlay : clickToPlay;
    }
    if (!adsScheduleId) {
      throw new Error(`No ad schedule Id found`);
    }
  }

  const advertisingDefaults = await (await fetch(`${JW_CDN_URL}/advertising/schedules/${adsScheduleId}.json`)).json();

  const schedule = [...advertisingDefaults?.schedule];
  let editableSchedule: any = schedule;
  if (!schedule) {
    throw new Error(`No ad schedule found in supplied ad config`);
  }

  let experimentGroup: any = null;
  try {
    experimentGroup =
      isClient() && ads
        ? await window?.ads?.getVideoExperimentGroup().catch((reason: string) => logger.debug(reason))
        : null;
  } catch (e: any) {
    logger.warn('Error thrown by window.ads.getVideoExperimentGroup', e);
  }

  const adsPpId = isClient() ? ads?.getVideoPpId() : null;

  const adTagUrl = await tryGetWindowProp<string, undefined>('adsTagUrl', { fallbackValue: undefined });

  if (!adTagUrl) {
    logger.warn(`adsSetup: window.adTagUrl not initialized`);
  }

  const modifyTag = () => {
    let dfpIdVal: any;
    const adTag =
      adTagUrl &&
      adTagUrl.replace('securepubads.g', 'pubads.g').replace('output=vast', 'output=xml_vmap1') +
        '&tfcd=0&npa=0&impl=s&vpmute=1&vpa=auto&vpos=preroll&ad_rule=1&cmsid=2546081&plcmt=2';

    if (isClient()) {
      const url = getUrl(window.location.href);
      dfpIdVal = url?.searchParams.get('dfp_id');
    }

    if (!editableSchedule) {
      const googletag: string =
        'https://pubads.g.doubleclick.net/gampad/ads?iu=/7783179/femina.se/video&description_url=https%3A%2F%2Fwww.femina.se&tfcd=0&npa=0&sz=1920x1080&cust_params=pos%3Dauto%26demandmanager%3D1%26category%3D__item-category__%26brand%3D__item-brand__%26creator%3D__item-creator__%26target_brand%3D__item-target_brand__&gdfp_req=1&unviewed_position_start=1&env=vp&impl=s&correlator=__random-number__&vpmute=1&vpa=auto&url=https%3A%2F%2Fwww.femina.se&vpos=preroll&ad_rule=1&cmsid=2546081&vid=__item-mediaid__&output=xml_vmap1&plcmt=2';
      editableSchedule.push({ tag: googletag });
    }

    if (dfpIdVal) {
      // It is from a test article, show a test ad.
      return editableSchedule.map(({ tag }: { tag: string }) => ({
        tag: replaceTag(adTag || tag, dfpIdVal),
      }));
    } else {
      return editableSchedule.map(({ tag }: { tag: string }) => ({
        tag: addArticleDataToAdTag({
          tag: adTag || tag,
          articleInfo,
          videoData: { wasYouplay },
          experimentGroup,
          adsPpId,
          isVerticalVideo,
          isSecondNativeArticleLoaded,
        }),
      }));
    }
  };

  return {
    schedule: modifyTag(),
    adscheduleid: adsScheduleId,
    noConsent: false,
  };
};
