import { VideoTranscriptTheme } from '@/components/VideoTranscript/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: VideoTranscriptTheme });

const Base = $({ as: 'div', slot: 'base' });
const Title = $({ as: 'h6', slot: 'title' });
const List = $({ as: 'ul', slot: 'list' });
const Line = $({ as: 'li', slot: 'line' });
const Text = $({ as: 'span', slot: 'text' });
const Time = $({ as: 'span', slot: 'time' });

export const VideoTranscript = Object.assign(Base, {
  Title,
  List,
  Line,
  Text,
  Time,
});
