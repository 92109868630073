import { B2B_USER_INFO } from '../constants';
import { UserInformation } from './types';


export const saveUserInformation = async (campaignId: string | null, userInformation: UserInformation) => {
  if (!campaignId) {
    return;
  }

  if (userInformation?.seenCampaigns?.hasOwnProperty(campaignId)) {
    userInformation.seenCampaigns[campaignId] = userInformation.seenCampaigns[campaignId] + 1;
  } else {
    userInformation.seenCampaigns[campaignId] = 1;
  }

  localStorage.setItem(B2B_USER_INFO, JSON.stringify(userInformation));
};

export const getUserInformation = (): UserInformation => {
  const b2bUserInfo = localStorage.getItem(B2B_USER_INFO);
  const defaultUserInformation: UserInformation = { seenCampaigns: {} };

  if (!b2bUserInfo) return defaultUserInformation;

  try {
    return JSON.parse(b2bUserInfo);
  } catch {
    return defaultUserInformation;
  }
};
