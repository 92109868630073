import { HORIZONTAL_ASPECT_RATIO, VERTICAL_ASPECT_RATIO } from '../constants';

export default function getAspectRatio(sources: any[] = []): string {
  const fileResolutionPrecedence = ['720p', '540p', '360p', '270p', '180p'];
  let fileSource = null;

  if (sources.length) {
    for (const resolution of fileResolutionPrecedence) {
      fileSource = sources.find((source) => source.label === resolution && source.type.includes('mp4'));
      if (fileSource) {
        break;
      }
    }
  }

  return fileSource?.height >= fileSource?.width ? VERTICAL_ASPECT_RATIO : HORIZONTAL_ASPECT_RATIO;
}
