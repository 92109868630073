import { tw } from '@/utils/tw';

const VideoTranscript = tw.theme({
  slots: {
    base: [
      'border',
      'flex-col',
      'hidden',
      'data-[open]:flex',
      'lg:col-start-2',
      'gap-6',
      'max-h-70',
      'p-3',
      'border-gray-400',
    ],
    title: '',
    list: [
      'scrollbar-thumb-gray-200',
      'scrollbar-track-white',
      'gap-4',
      'scrollbar-w-4',
      'overflow-y-auto',
      'flex',
      'flex-col',
      'scrollbar',
      'scrollbar-thumb-rounded-full',
    ],
    line: ['gap-2', 'flex', 'flex-row'],
    text: '',
    time: ['text-gray-700'],
  },
});

export default Object.assign(VideoTranscript, {});
