import { JWPlayer, VideoPlayerType } from '../types/video';
import { PlayerFeature } from './types';

let observerInstance: IntersectionObserver | null = null;
const floaters = new WeakMap();

function getObserver(container: HTMLElement, threshold = 0.3): IntersectionObserver {
  if (observerInstance == null) {
    observerInstance = createObserver(container, threshold);
  }

  return observerInstance;
}

function createObserver(container: HTMLElement, threshold = 0.3) {
  const floatObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((e) => {
        const data = floaters.get(e.target);
        if (!data) {
          return;
        }

        // data.initialized: don't float when IntersectionObserver runs the callback immediately on page load.
        if (data.initialized && data.shouldFloat && window.jwplayer) {
          const jwplayer = window.jwplayer;
          const player = jwplayer(e.target);
          const belowScreen = e.boundingClientRect.top >= 0;
          const shouldFloat = !belowScreen && !e.isIntersecting;
          player.setFloating(shouldFloat);
        }

        data.initialized = true;
      });
    },
    { threshold }
  );

  return floatObserver;
}

export default function setup(
  player: JWPlayer,
  { floating, autostart }: VideoPlayerType,
  isMobile: boolean
): PlayerFeature {
  if (!isMobile || (floating && (!floating.mode || floating.mode === 'never'))) {
    return {
      preConfig: () => {
        return {};
      },
      onReady: () => {},
    };
  }

  // Disable built-in floating timing.
  const preConfig = () => {
    return {
      floating: {
        dismissible: true,
        mode: 'never',
      },
    };
  };

  const onReady = () => {
    const container = player.getContainer();
    floaters.set(container, {
      shouldFloat: autostart === 'viewable',
      initialized: false,
    });

    getObserver(container).observe(container);
  };

  return {
    preConfig,
    onReady,
  };
}
