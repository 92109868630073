import { logger } from '@/utils/logger';
import { useJWPlayer, VIDEO_PLAYED_TYPE } from '../JWPlayerContext';
import { JW_CDN_URL } from '../constants';
import { Complete } from '../constants/playEvents';
import { usePlayerEvent } from './usePlayerEvent';

type VideoType =
  | typeof VIDEO_PLAYED_TYPE.NATIVE
  | typeof VIDEO_PLAYED_TYPE.RECOMMENDED
  | typeof VIDEO_PLAYED_TYPE.VERTICAL;

interface UseReplaceNextVideoProps {
  mediaId?: string | null;
  type: VideoType;
  callback?: () => void;
  replaceCurrentVideo?: boolean;
}

export function useReplaceNextVideo({ mediaId, type, callback, replaceCurrentVideo }: UseReplaceNextVideoProps) {
  const { player, playedTypes, updatePlayedTypes, videoPlacementPriority } = useJWPlayer();
  const canPlayType = (type: VideoType): boolean => {
    const typeIndex = videoPlacementPriority.indexOf(type);
    return videoPlacementPriority.slice(0, typeIndex).every((priorType) => playedTypes.includes(priorType));
  };

  const fetchMediaInfo = async (mediaId: string) => {
    const data = await fetch(`${JW_CDN_URL}/media/${mediaId}`);
    const {
      playlist: [media],
    } = await data.json();

    return media;
  };

  const replaceVideo = async (mediaId: string) => {
    if (canPlayType(type)) {
      player?.setPlaylistItemCallback(async () => {
        const media = await fetchMediaInfo(mediaId).catch((error) => {
          logger.catch(`setPlaylistItemCallback error: `, error);
          return;
        });

        if (callback) callback();
        if (!playedTypes.includes(type)) updatePlayedTypes(type);

        player?.removePlaylistItemCallback();
        return media;
      });
    }
  };

  if (replaceCurrentVideo && mediaId) replaceVideo(mediaId);

  usePlayerEvent(player, Complete, () => {
    mediaId && replaceVideo(mediaId);
  });
}
