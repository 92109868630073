import { useAppState } from '@/hooks/useAppState';
import { useEffect } from 'react';

export const useCorrectPlayerSize = ({ player }: { player: { resize: (width: string, height: string) => void } }) => {
  const [{ isVideoReelLoaded }] = useAppState();

  useEffect(() => {
    if (isVideoReelLoaded) {
      /** 
        This is a bug from JW Reels which they might fix in future.
        This is a workaround to make sure the videos on page do not having styling issues
        when reels are loaded.
      **/
      player?.resize('100%', '100%');
    }
  }, [isVideoReelLoaded, player]);
};
