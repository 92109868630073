import { useClassName } from '@/styles/JwVideoVideo';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import { VideoActionsModalComponent } from './Video.Actions.Modal';

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: 'div' });
const ProminentTag = GenericComponent({ as: 'div', styles: { key: 'prominentTag' } });
const Tags = GenericComponent({ as: 'div', styles: { key: 'tags' } });
const Group = GenericComponent({ as: 'div', styles: { key: 'group' } });
const Title = GenericComponent({ as: 'h2', styles: { key: 'title' } });
const Description = GenericComponent({ as: 'p', styles: { key: 'description' } });
const Actions = GenericComponent({ as: 'div', styles: { key: 'actions' } });
const ActionsTranscript = GenericComponent({ as: 'button', styles: { key: 'actions_transcript' } });
const ActionsShare = GenericComponent({ as: 'button', styles: { key: 'actions_share' } });

export const Video = Object.assign(Base, {
  ProminentTag,
  Tags,
  Group,
  Title,
  Description,
  Actions: Object.assign(Actions, {
    Share: ActionsShare,
    Transcript: ActionsTranscript,
    Modal: VideoActionsModalComponent,
  }),
});
