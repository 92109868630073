import { JWPlayer } from '../types/video';
import { Track } from './getVideoInformation';

export const addCaptions = (
  player: JWPlayer,
  {
    tracks,
    publishedAt,
    aspectRatio,
  }: {
    tracks: Track[];
    publishedAt: string;
    aspectRatio: string;
  },
) => {
  if (!player) return;
  // Show captions for videos published after 2024-08-26 with 16:9 aspect ratio
  const shouldShowCaptions = tracks.length && new Date(publishedAt) >= new Date('2024-08-26') && aspectRatio === '16:9';
  if (shouldShowCaptions) {
    player.setCurrentCaptions(tracks.length - 1);
    player.setCaptions({
      color: '#ffffff',
      backgroundColor: '#000000',
      backgroundOpacity: 50,
      edgeStyle: 'dropshadow',
      fontSize: 14,
      windowColor: '#000000',
      windowOpacity: 75,
    });
  }
};
