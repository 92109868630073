import { useClassName } from '@/styles/JwVideoArticle';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import Script from 'next/script';
import { JWPlayerProvider } from './JWPlayerContext';
import { VideoArticleContainerComponent } from './VideoArticle.Container';
import { VideoArticleExitComponent } from './VideoArticle.Exit';
import { VideoArticleExitLinkComponent } from './VideoArticle.Exit.Link';
import { VideoArticleExitMarketingTextComponent } from './VideoArticle.Exit.MarketingText';
import { VideoArticleHeaderComponent } from './VideoArticle.Header';
import { VideoArticleHeaderLinkComponent } from './VideoArticle.Header.Link';
import { VideoArticleHeaderTitleComponent } from './VideoArticle.Header.Title';
import { VideoArticleJWPlayerComponent } from './VideoArticle.JWPlayer';
import { VideoArticleMetaDataComponent } from './VideoArticle.MetaData';
import { VideoArticleUnmuteComponent } from './VideoArticle.Unmute';
import { VideoArticleUnmuteLabelComponent } from './VideoArticle.Unmute.Label';
import { VideoArticleVideoContainerComponent } from './VideoArticle.VideoContainer';
import { VideoArticleVideoContainerDurationComponent } from './VideoArticle.VideoContainer.Duration';
import { VideoArticleVideoContainerNextVideoComponent } from './VideoArticle.VideoContainer.NextVideo';
import { VideoArticleVideoContainerNextVideoDurationComponent } from './VideoArticle.VideoContainer.NextVideo.Duration';
import { VideoArticleVideoContainerNextVideoTitleComponent } from './VideoArticle.VideoContainer.NextVideo.Title';
import { VideoArticleDescriptionComponent } from './VideoArticle.VideoDescription';

export interface VideoArticleProps extends ComponentProps<'figure'> {
  playerSrc?: string;
  isFullWidth?: boolean;
  size?: ClassNameProp<'horizontal' | 'vertical'>;
}

const VideoArticleComponent: Component<VideoArticleProps> = ({ playerSrc, isFullWidth, children, ...props }) => {
  const className = useClassName('', props, { defaults: { size: 'horizontal' } });

  return (
    <>
      {playerSrc && <Script src={playerSrc} />}

      <JWPlayerProvider>
        <figure itemScope itemType="https://schema.org/VideoObject" {...mergeProps({ className }, props)}>
          {children}
        </figure>
      </JWPlayerProvider>
    </>
  );
};

export const VideoArticle = Object.assign(VideoArticleComponent, {
  Container: VideoArticleContainerComponent,
  MetaData: VideoArticleMetaDataComponent,
  Header: Object.assign(VideoArticleHeaderComponent, {
    Link: VideoArticleHeaderLinkComponent,
    Title: VideoArticleHeaderTitleComponent,
  }),
  VideoContainer: Object.assign(VideoArticleVideoContainerComponent, {
    Duration: VideoArticleVideoContainerDurationComponent,
    NextVideo: Object.assign(VideoArticleVideoContainerNextVideoComponent, {
      Title: VideoArticleVideoContainerNextVideoTitleComponent,
      Duration: VideoArticleVideoContainerNextVideoDurationComponent,
    }),
  }),
  Exit: Object.assign(VideoArticleExitComponent, {
    Link: VideoArticleExitLinkComponent,
    MarketingText: VideoArticleExitMarketingTextComponent,
  }),
  JWPlayer: VideoArticleJWPlayerComponent,
  Unmute: Object.assign(VideoArticleUnmuteComponent, {
    Label: VideoArticleUnmuteLabelComponent,
  }),
  Description: VideoArticleDescriptionComponent,
});
