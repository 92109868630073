import { Component } from '@/types/component';
import { VideoMetaData } from './types/video';

export interface VideoArticleMetaDataProps extends VideoMetaData {}

export const VideoArticleMetaDataComponent: Component<VideoArticleMetaDataProps> = (props) => {
  const { title, description, uploadDate, duration, thumbnailUrl, contentUrl } = props;

  return (
    <>
      {title && <meta itemProp="name" content={title} />}
      {description && <meta itemProp="description" content={description} />}
      {uploadDate && <meta itemProp="uploadDate" content={uploadDate} />}
      {duration && <meta itemProp="duration" content={duration} />}
      {thumbnailUrl && <meta itemProp="thumbnailUrl" content={thumbnailUrl} />}
      {contentUrl && <meta itemProp="contentUrl" content={contentUrl} />}
    </>
  );
};
