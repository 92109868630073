import { logger } from '@/utils/logger';
import { JWPlayer } from '../types/video';
import { PlayerFeature } from './types';

export default function setup(player: JWPlayer): PlayerFeature {
  const onReady = () => {
    player.on('adRequest', (response: any) => {
      try {
        if (response && window.adStats) {
          window.adStats.add([
            {
              placement: 'preroll',
              gptEvent: 'slotRequested',
            },
          ]);
          window.adStats.push();
        }
      } catch (error: any) {
        logger.catch(`Video adWatch error: ${error.message}`);
      }
    });
  };
  const preConfig = () => {
    return {
      video: {
        adRequest: true,
      },
    };
  };

  return {
    preConfig,
    onReady,
  };
}
