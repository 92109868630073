import { OptionalLink } from '@/components/OptionalLink';
import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export interface VideoArticleHeaderLinkProps extends ComponentProps<'span'> {
  videoPageLink?: string;
  className?: string;
  mediaId?: string;
}

export const VideoArticleHeaderLinkComponent: Component<VideoArticleHeaderLinkProps> = ({
  videoPageLink,
  mediaId,
  children,
  ...props
}) => {
  const className = useClassName('header_link', props);

  return (
    <OptionalLink
      href={videoPageLink}
      aria-label="Gå till video"
      content={<span {...mergeProps({ className }, props)}>{children}</span>}
    />
  );
};
