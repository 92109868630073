import { getRecommendationMicroservice } from '@/utils/getRecommendationMicroservice';
import { getSite } from '@/utils/getSite';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { logger } from '@/utils/logger';
import getConfig from 'modules/config';
import { useCallback, useEffect, useState } from 'react';
import { isNotTrue } from 'typesafe-utils';
import { useJWPlayer, VIDEO_PLAYED_TYPE } from '../JWPlayerContext';
import { VIEWED_VIDEOS } from '../constants';
import { Play } from '../constants/playEvents';
import { usePlayerEvent } from './usePlayerEvent';
import { useReplaceNextVideo } from './useReplaceNextVideo';

export function useNextRecommendedVideo(setShowNextVideoPopup: (show: boolean) => void) {
  const { player, currentMedia } = useJWPlayer();
  const [viewedVideos, setViewedVideos] = useState<string[]>();
  const [nextMediaId, setNextMediaId] = useState<string | null>(null);

  const brand = getSite();
  const isDisabled = isNotTrue(getConfig('nextVideoRecommendation.enabled'));

  useEffect(() => {
    if (isDisabled) return;

    // load initial value from sessionStorage
    if (!viewedVideos) {
      setViewedVideos(getViewedVideos());
    }

    if (viewedVideos?.length) {
      updateViewedVideos(viewedVideos);

      if (player && brand) {
        loadNextRecommendedVideo(viewedVideos, brand).then((mediaId) => {
          setShowNextVideoPopup(false);
          mediaId && setNextMediaId(mediaId);
        });
      }
    }
  }, [viewedVideos, brand, player]);

  useReplaceNextVideo({
    mediaId: !viewedVideos?.includes(nextMediaId ?? '') ? nextMediaId : null,
    type: VIDEO_PLAYED_TYPE.RECOMMENDED,
  });

  const onPlay = useCallback(async () => {
    if (!currentMedia || !viewedVideos) return;

    if (viewedVideos.length > 50) {
      viewedVideos.shift();
    }

    if (!viewedVideos.includes(currentMedia.mediaid)) {
      setViewedVideos([...viewedVideos, currentMedia.mediaid]);
    }
  }, [currentMedia, viewedVideos]);

  usePlayerEvent(player, Play, onPlay);

  function getViewedVideos(): string[] {
    const viewedVideosJson: string | null = sessionStorage.getItem(VIEWED_VIDEOS);

    if (viewedVideosJson === null) {
      return [];
    }

    try {
      const viewedVideos: string[] = JSON.parse(viewedVideosJson);
      if (Array.isArray(viewedVideos)) {
        return viewedVideos;
      }
    } catch (error) {
      logger.catch('Error parsing viewed videos JSON:', error);
    }

    return [];
  }

  function updateViewedVideos(viewedVideos: string[]): void {
    sessionStorage.setItem(VIEWED_VIDEOS, JSON.stringify(viewedVideos));
  }

  async function loadNextRecommendedVideo(viewedVideos: string[], brand: string): Promise<string | null> {
    const nextRecommendedVideoId = await getNextRecommendedVideoId(viewedVideos, brand);
    return nextRecommendedVideoId ?? null;
  }

  async function getNextRecommendedVideoId(viewedContent: string[], brand: string): Promise<string | null> {
    const baseUrl = getUrl('/videoRecommendations', getRecommendationMicroservice());
    if (!baseUrl) return null;

    const payload = {
      viewedContent,
      brand,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 60000,
    };

    try {
      const response = await http.post(baseUrl.href, payload, config);
      return response?.data?.nextVideoRecommendation[0] ?? null;
    } catch (error) {
      logger.catch(`Unable to get next recommended video ID: ${error}`);
      return null;
    }
  }
}
