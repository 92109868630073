import { VIDEO_ASPECT_RATIO } from '../../constants';
import { JWPlayer, VideoPlayerType } from '../types/video';
import { PlayerFeature } from './types';

// TODO: remove if not needed after launchof vertical video
export default function setup(player: JWPlayer, { aspectRatio }: VideoPlayerType, isMobile: boolean): PlayerFeature {
  // Force 16:9 aspect for all videos on mobile if not fullscreen
  const overrideAspect = isMobile ? VIDEO_ASPECT_RATIO.HORIZONTAL : aspectRatio;
  const configOverrides = overrideAspect ? { aspectRatio: overrideAspect } : {};

  const onReady = () => {
    player.on('fullscreen', ({ fullscreen }: { fullscreen: boolean }) => {
      player.setConfig({
        aspectratio: fullscreen ? aspectRatio : overrideAspect,
      });
    });
  };

  return {
    preConfig: () => configOverrides,
    onReady,
  };
}
